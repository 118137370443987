.status_listing_section {padding: 50px 0;}
.status_listing_card h1{font-size: 25px;}
.status_listing_card {
    /*margin: 0 auto;*/
    /*text-align: center;*/
    /*display: -webkit-flex;*/
    /*display: flex;*/
    border-radius: 20px !important;
    /*-webkit-justify-content: center;*/
    justify-content: center;
    -webkit-flex-wrap: wrap;
    border:none !important;
    flex-wrap: wrap;
    margin-top: 15px;
    /*padding: 1.5%;*/
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    box-shadow: 0 4px 10px rgba(0,0,0,0.16), 0 4px 10px rgba(0,0,0,0.23);
}
.status_listing_card_header{
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.form-control:focus{border-color: #dee2e6 !important;box-shadow: none !important;outline:none !important;}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper{min-width: 300px !important;min-height: 200px !important;}
.pagination{float: right !important;}
.paginate_button {margin-right: 5px !important;cursor:pointer;}
/*.dataTables_filter{display: none !important;}*/
.dataTables_length, .dataTables_info{margin-bottom: 20px !important;}
.css-j79u8j-MuiButtonBase-root-MuiIconButton-root:hover{background: none !important;}
.page-link:focus{box-shadow: none !important;}
.dataTables_length select {
  display: inline !important;
  width: 70px !important;
  /*padding: 2px !important;*/
}
.modal-backdrop{opacity: 0.2 !important;}
.cstm-form-group .react-datepicker-wrapper{display: block !important;}
.user-date .react-datepicker-wrapper{width:60% !important;}
.user-date .cstm-user-datepicker{width:100% !important;width: 100% !important;border: 1px solid #ccc;border-radius: 3px; height: 35px !important;}
.user-date .cstm-user-datepicker:focus-visible {
  outline: none !important;
}
.dataTables_length label{
  display: inline !important;
}
.dataTables_filter{text-align: right !important;}
.dataTables_filter .form-control {
  display: inline !important;
  width: 30% !important;
  margin-left: 2px !important;
}
.dataTables_filter .form-control:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: #dee2e6 !important;
}
.dataTables_filter label {
  display: inline !important;
}
.dataTables_length .form-select:focus{
  outline: none !important;
  box-shadow: none !important;
  border-color: #dee2e6 !important;
}
.form-check-input:focus{box-shadow: none !important;border-color:#dee2e6 !important;}
/*.status-table tr th .table_error, .status-table tr th .table_timestamp,*/
/*.status-table tr td .table_error, .status-table tr td .table_timestamp{width:200px !important;}*/
/*.status-table tr th, .status-table tr td{text-align:center !important; }*/
/*.form-check .form-check-input{float: none !important;}*/
/*.form-check .form-check-label{padding-left: 5px !important;}*/